import { default as index7LUDDEZY7IMeta } from "/var/www/carnaval-live/nuxt-pos/pages/index.vue?macro=true";
import { default as _91id_93zIhZ1UHx4TMeta } from "/var/www/carnaval-live/nuxt-pos/pages/order/[id].vue?macro=true";
import { default as privacyv56Y0oodsaMeta } from "/var/www/carnaval-live/nuxt-pos/pages/privacy.vue?macro=true";
import { default as voorwaardenGCQVGeqjzoMeta } from "/var/www/carnaval-live/nuxt-pos/pages/voorwaarden.vue?macro=true";
export default [
  {
    name: index7LUDDEZY7IMeta?.name ?? "index",
    path: index7LUDDEZY7IMeta?.path ?? "/",
    meta: index7LUDDEZY7IMeta || {},
    alias: index7LUDDEZY7IMeta?.alias || [],
    redirect: index7LUDDEZY7IMeta?.redirect || undefined,
    component: () => import("/var/www/carnaval-live/nuxt-pos/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zIhZ1UHx4TMeta?.name ?? "order-id",
    path: _91id_93zIhZ1UHx4TMeta?.path ?? "/order/:id()",
    meta: _91id_93zIhZ1UHx4TMeta || {},
    alias: _91id_93zIhZ1UHx4TMeta?.alias || [],
    redirect: _91id_93zIhZ1UHx4TMeta?.redirect || undefined,
    component: () => import("/var/www/carnaval-live/nuxt-pos/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: privacyv56Y0oodsaMeta?.name ?? "privacy",
    path: privacyv56Y0oodsaMeta?.path ?? "/privacy",
    meta: privacyv56Y0oodsaMeta || {},
    alias: privacyv56Y0oodsaMeta?.alias || [],
    redirect: privacyv56Y0oodsaMeta?.redirect || undefined,
    component: () => import("/var/www/carnaval-live/nuxt-pos/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: voorwaardenGCQVGeqjzoMeta?.name ?? "voorwaarden",
    path: voorwaardenGCQVGeqjzoMeta?.path ?? "/voorwaarden",
    meta: voorwaardenGCQVGeqjzoMeta || {},
    alias: voorwaardenGCQVGeqjzoMeta?.alias || [],
    redirect: voorwaardenGCQVGeqjzoMeta?.redirect || undefined,
    component: () => import("/var/www/carnaval-live/nuxt-pos/pages/voorwaarden.vue").then(m => m.default || m)
  }
]