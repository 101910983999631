
import { updateAppConfig } from '#app'
import { defuFn } from '/var/www/carnaval-live/nuxt-pos/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "nuxt": {
    "buildId": "11e42e6b-fcf4-4d00-bd36-0c9cf546cf62"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/var/www/carnaval-live/nuxt-pos/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
